:root {
    font-size: 62.5%;
    --main-bg-color: #DDD;
    --primary-color: #B40000;
}

@font-face {
    font-family: "Roboto Thin";
    src: url('/src/assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    src: url('/src/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto Semi Bold";
    src: url('/src/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto Bold";
    src: url('/src/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

* {
    font-family: "Roboto";
    letter-spacing: 0.5px;
    font-size: 1.6rem;
}
